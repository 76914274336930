import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import heading from '../assets/images/heading.png';

export default function ResetPassword() {

    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirm_password, setconfirm_password] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    let istemp_user_id = localStorage.getItem('user_id');
    if (istemp_user_id === null) {
        setTimeout(() => {
            navigate("/");
        }, 10)
    }


    // const [allNewEntry, setNewEntry] = useState([]);

    // const encrypted = CryptoJS.AES.encrypt(JSON.stringify(password), SECRET_KEY).toString();
    // alert(encrypted);

    // const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    // alert(decrypted);

    // localStorage.setItem(password, encrypted);

    const onpasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const onconfirm_passwordChange = (e) => {
        setconfirm_password(e.target.value);
    };
    const onsubmitform = (e) => {

        setErrorMessage("");
        e.preventDefault();
        if (password === confirm_password) {
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append("password", password);
            formdata.append("confirm_password", confirm_password);
            formdata.append("user_id", localStorage.getItem("user_id"));
            $.ajax({
                type: "POST",
                url: `${process.env.REACT_APP_GLOBAL_BASEURL}/commonapi/Login/reset_password`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log(data);
                    if (data === "success") {
                        //  navigate("/");

                        setSuccessMessage("Updated Successfully redirecting to Login page");
                        setTimeout(() => {
                            navigate("/");
                        }, 3000)


                    }
                },
                error: function (err) {
                    console.log(err);

                },
            });
        } else {

            setErrorMessage("Password and Confirm Password must be same");
        }
    };

    return (
        <div className="loginPage">
            <div className="login" style={{ paddingTop: 50 }}>

                <div style={{ backgroundColor: 'white', height: '75%' }} >
                    <img className="image" src={heading} style={{ height: 80, width: '100%' }} />

                    <div >
                        <div  >

                            <p style={{ textAlign: 'center', fontSize: 16 }}>{sessionStorage.getItem("temp_user_id")}</p>
                            <div >
                                <h3 style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold', fontSize: 20 }}>RESET PASSWORD</h3>
                            </div>
                            <div>
                                <label htmlFor="user_id" style={{ marginLeft: 20 }}>Password</label>
                                <div style={{ textAlign: 'center' }}>

                                    <input
                                        type="password"
                                        name="user_id"
                                        required
                                        placeholder="Password"
                                        style={{ border: '1px solid gray', width: '40%', height: 40, paddingLeft: 8, fontSize: 12, borderRadius: 3, fontSize: 'small', width: '90%' }}

                                        value={password}
                                        onChange={onpasswordChange}
                                    />
                                </div>
                            </div>
                            <div >
                                <label htmlFor="password" style={{ marginLeft: 20, marginTop: 20 }}>Confirm Password</label>
                                <div style={{ textAlign: 'center' }}>
                                    <input
                                        type="password"
                                        name="password"
                                        autocomplete="off"
                                        required
                                        placeholder="Confirm Password"
                                        style={{ border: '1px solid gray', width: '40%', height: 40, paddingLeft: 8, fontSize: 12, borderRadius: 3, fontSize: 'small', width: '90%' }}

                                        value={confirm_password}
                                        onChange={onconfirm_passwordChange}
                                    />
                                </div>

                            </div>
                            {errorMessage && <div className="error"> <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>{errorMessage}</p> </div>}
                            {successMessage && <div style={{ marginTop: 15, textAlign: 'center' }}> <p style={{ color: 'green', fontSize: 14, fontWeight: 'bold' }}>{successMessage}</p> </div>}


                            <div style={{ textAlign: 'center' }}>
                                <button
                                    id="submitbutton"
                                    type="submit"
                                    onClick={onsubmitform}
                                    className="login_button"

                                >
                                    Submit
                                </button>
                            </div>

                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <a style={{ fontSize: 18 }} href="/">Go to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
