import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import heading from '../assets/images/heading.png';
import axios from "axios";

export default function ForgotPassword() {
    let myStyle_otp = {
        display: "none",
    };
    const navigate = useNavigate();
    const [user_id, setUser_id] = useState("");
    const [contact_no, setPassword] = useState("");
    const [OTP, setOTP] = useState(new Array(6).fill(""));
    const [errorMessage, setErrorMessage] = useState("");
    const [msg, setMsg] = useState("");
    const [displayOTP, setDisplayOTP] = useState(false);
    const [isOTPSectionOpen, setIsOTPSectionOpen] = useState(false);
    const [otpno, setOtpNo] = useState("");
    // const [allNewEntry, setNewEntry] = useState([]);
    const onuser_idChange = (e) => {
        setUser_id(e.target.value);
    };
    const onpasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const onotpChange = (element, index) => {
        console.log(element.value)
        if (isNaN(element.value)) return false;

        setOTP([...OTP.map((d, idx) => (idx === index ? element.value : d))]);
        //setOtp(enteredOtp.join(""));

        //Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const onsubmitform_otp = (e) => {
        e.preventDefault();
        const enteredOtp = OTP.join("");
        console.log("enteredOtp", enteredOtp);
        console.log("otpN0", otpno)
        if (otpno == enteredOtp) {
            localStorage.setItem("user_id", user_id)
            navigate("/ResetPassword");
        } else {
            setErrorMessage("Invalid OTP!");
        }
    };

    const onsubmitresend_otp = (e) => {
        e.preventDefault();
        navigate("/ForgotPassword");

        document.getElementById("forgot_password_div").style.display = "none";
        document.getElementById("otp").style.display = "block";
        axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/commonapi/Misc/send_pwd_reset?phone=${contact_no}`)
            .then(OTPresponse => {
                console.log('send OTPHandler:otp response \n', OTPresponse.data);
                if (OTPresponse.data) {

                    if (OTPresponse.data.OTP_SMS_RESPONSE == "No") {
                        setOtpNo(OTPresponse.data.OTP);
                        setIsOTPSectionOpen(true);
                        setDisplayOTP(true);
                    } else {
                        setOtpNo(OTPresponse.data.OTP);
                        setIsOTPSectionOpen(true);
                        setDisplayOTP(false);

                    }

                }

            }).catch((err) => {
                console.log(' otp send failed', err);

            })
        // let length = 6;
        // var random_no = (
        //     "0".repeat(length) + Math.floor(Math.random() * 10 ** length)
        // ).slice(-length);
        // sessionStorage.setItem("otp_val", random_no);
    };

    const onsubmitform = (e) => {
        console.log("submit handelerr entered")
        setMsg("");
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append("user_id", user_id);
        formdata.append("contact_no", contact_no);
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/commonapi/Login/forgot_password`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log(data);
                if (data === "success") {
                    //navigate("/ForgotPassword");

                    document.getElementById("forgot_password_div").style.display = "none";
                    document.getElementById("otp").style.display = "block";
                    // let length = 6;
                    // var random_no = (
                    //     "0".repeat(length) + Math.floor(Math.random() * 10 ** length)
                    // ).slice(-length);
                    // sessionStorage.setItem("otp_val", random_no);
                    // sessionStorage.setItem("temp_user_id", user_id);

                    axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/commonapi/Misc/send_pwd_reset?phone=${contact_no}`)
                        .then(OTPresponse => {
                            console.log('send OTPHandler:otp response \n', OTPresponse.data);
                            if (OTPresponse.data) {

                                if (OTPresponse.data.OTP_SMS_RESPONSE == "No") {
                                    setOtpNo(OTPresponse.data.OTP);
                                    setIsOTPSectionOpen(true);
                                    setDisplayOTP(true);
                                } else {
                                    setOtpNo(OTPresponse.data.OTP);
                                    setIsOTPSectionOpen(true);
                                    setDisplayOTP(false);

                                }

                            }

                        }).catch((err) => {
                            console.log(' otp send failed', err);

                        })
                } else {
                    setMsg("User ID or Contact No. Does Not Match with Database");
                }
            },
            error: function (err) {
                console.log(err);
            },
        });
    };
    return (


        <div className="loginPage">
            <div className="login" style={{ paddingTop: 50 }}>

                <div style={{ backgroundColor: 'white', height: '75%' }} >
                    <img className="image" src={heading} style={{ height: 80, width: '100%' }} />

                    <div>
                        <h3 style={{ textAlign: 'center', marginTop: 20, fontWeight: 'bold', fontSize: 20 }}>FORGOT PASSWORD</h3>
                    </div>
                    <div >
                        <div id="forgot_password_div">
                            {/* <img className="image" src={chi} />
                            <img className="images" src={doctors} /> */}
                            <div >
                                <h6 style={{ marginTop: 15 }}></h6>
                            </div>
                            <div >
                                <label htmlFor="user_id" ></label>
                                <br />
                                <label style={{ fontSize: 13, color: '#7781A9', marginLeft: 15 }}>User Id *</label>

                                <div style={{ textAlign: 'center' }}>

                                    <input
                                        type="text"
                                        name="user_id"
                                        required
                                        placeholder="User Id"
                                        value={user_id}
                                        onChange={onuser_idChange}
                                        style={{ border: '1px solid gray', width: '40%', height: 40, paddingLeft: 8, fontSize: 12, borderRadius: 3, fontSize: 'small', width: '90%' }}

                                    />
                                </div>
                            </div>
                            <label style={{ fontSize: 13, color: '#7781A9', marginLeft: 15, marginTop: 20 }}>Contact No *</label>

                            <div style={{ textAlign: 'center' }} >
                                <input
                                    type="text"
                                    name="contact_no"
                                    autocomplete="off"
                                    required
                                    placeholder="Contact No."
                                    value={contact_no}
                                    onChange={onpasswordChange}
                                    style={{ border: '1px solid gray', width: '40%', height: 40, paddingLeft: 8, fontSize: 12, borderRadius: 3, fontSize: 'small', width: '90%' }}

                                />
                            </div>

                            {
                                msg ?
                                    <p style={{ fontSize: 14, fontWeight: 'bold', color: 'red', marginTop: 5, textAlign: 'center' }}>{msg}</p>
                                    :
                                    null
                            }
                            <div style={{ textAlign: 'center' }}>

                                <button
                                    type="submit"
                                    onClick={onsubmitform}
                                    className="login_button"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div id="otp" style={myStyle_otp}>
                            <div >
                                <h6 style={{ marginTop: 15, textAlign: 'center' }}>OTP</h6>
                            </div>
                            {displayOTP ? <p>Your Current OTP is: {otpno}</p> : null}
                            <div style={{ textAlign: 'left', marginLeft: 15 }} >

                                {OTP.map((data, index) => {
                                    return (
                                        <input
                                            className="otp-field"
                                            type="text"
                                            name="otp"
                                            maxLength="1"
                                            key={index}
                                            value={data}
                                            style={{ width: 40, paddingLeft: 10, marginLeft: 10, fontWeight: 'bold', fontSize: 12, marginTop: 10 }}
                                            onChange={(event) => onotpChange(event.target, index)}
                                            onFocus={e => e.target.select()}
                                        />
                                    );
                                })}
                            </div>
                            {errorMessage && <div className="error"> <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold', marginLeft: 130 }}>{errorMessage}</p> </div>}


                            <div style={{ marginTop: 20, textAlign: 'center' }} onClick={onsubmitresend_otp}>
                                <a style={{ fontSize: 16, cursor: 'pointer', color: 'blue', fontWeight: 'bold' }}>Resend OTP? </a>
                            </div>

                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <button
                                    type="submit"
                                    onClick={onsubmitform_otp}
                                    className="login_button"

                                >
                                    Submit
                                </button>
                            </div>
                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <a style={{ fontSize: 18 }} href="/">Go to Login </a>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>










    );
}
